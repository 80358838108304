import React, { useState, useEffect, useRef } from "react";
import "./carousel.css";

interface CarouselItem {
  id: number;
  src: string;
}

const carouselItems: CarouselItem[] = [
  { id: 1, src: "https://www.lh.just-ready.com/" },
  { id: 2, src: "https://www.fandd.just-ready.com/" },
  { id: 3, src: "https://lehet.eu/" },
  { id: 4, src: "https://www.j.just-ready.com/" },
];

const Carousel: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [visibleItems, setVisibleItems] = useState<CarouselItem[]>([]);
  const [iframeActive, setIframeActive] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);
  const touchStartY = useRef<number>(0);
  const touchEndY = useRef<number>(0);

  const moveCarousel = (direction: "next" | "prev") => {
    setIframeActive(false); // Ha váltasz, tiltsd le az iframe-et
    setSelectedIndex((prevIndex) => {
      if (direction === "next") {
        return prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1;
      } else {
        return prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1;
      }
    });
  };

  const handleIframeClick = (index: number) => {
    if (index === 1) {
      // Csak a középső (selected) iframe legyen aktív
      setIframeActive(true);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
    touchEndY.current = e.touches[0].clientY;

    // Ha az iframe aktív, engedjük a függőleges görgetést, de blokkoljuk az oldalirányút
    if (iframeActive) {
      // Ha a mozgás inkább függőleges (vertikális), akkor ne blokkoljuk
      const isVerticalScroll = Math.abs(touchStartY.current - touchEndY.current) > Math.abs(touchStartX.current - touchEndX.current);
      if (isVerticalScroll) {
        e.preventDefault(); // Engedjük a görgetést
      } else {
        e.preventDefault(); // Megakadályozzuk az oldalirányú görgetést
      }
    }
  };

  const handleTouchEnd = () => {
    if (isScrolling) {
      const direction =
        touchStartX.current - touchEndX.current > 0 ? "next" : "prev";
      moveCarousel(direction);
    } else {
      setIframeActive(true);
    }
    setIsScrolling(false);
  };

  useEffect(() => {
    const updateVisibleItems = () => {
      const prevIndex =
        (selectedIndex - 1 + carouselItems.length) % carouselItems.length;
      const nextIndex = (selectedIndex + 1) % carouselItems.length;
      setVisibleItems([
        carouselItems[prevIndex],
        carouselItems[selectedIndex],
        carouselItems[nextIndex],
      ]);
    };
    updateVisibleItems();
  }, [selectedIndex]);

  const handleDotClick = (index: number) => {
    setSelectedIndex(index);
    setIframeActive(false); 
  };

  return (
    <section className="carousel-section">
      <h1 className="carousel-title">Munkáink</h1>
      <div
        className="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="carousel">
          {visibleItems.map((item, index) => {
            const className =
              index === 0
                ? "prev"
                : index === 1
                ? "selected"
                : "next";

            return (
              <div
                key={item.id}
                className={`iframe-container ${className} ${
                  iframeActive && index === 1 ? "active" : ""
                }`}
                onClick={() => handleIframeClick(index)}
              >
                <iframe
                  src={item.src}
                  title={`Carousel item ${item.id}`}
                  width="380px"
                  height="400px"
                  frameBorder="0"
                  className="no-scrollbar"
                  style={{
                    pointerEvents: iframeActive && index === 1 ? "auto" : "none",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="carousel-controls">
        <button
          className="carousel-arrow carousel-arrow-left"
          onClick={() => moveCarousel("prev")}
        >
          &#9664;
        </button>
        <div className="carousel-dots">
          {carouselItems.map((_, index) => (
            <span
              key={index}
              className={`dot ${selectedIndex === index ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
        <button
          className="carousel-arrow carousel-arrow-right"
          onClick={() => moveCarousel("next")}
        >
          &#9654;
        </button>
      </div>
    </section>
  );
};

export default Carousel;
